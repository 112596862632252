import * as React from 'react';
import { BaSeStaticAsset } from '../static-asset/static-asset';
import { ImageProps } from './image-props';

export const BaSeEmoticon: React.FC<
  Pick<ImageProps, 'name' | 'description'>
> = ({ name, description }) => (
  <BaSeStaticAsset
    classIdentifier="BaSe--emoticon"
    description={description}
    path={`images/emoticons/${name}.svg`}
  />
);

BaSeEmoticon.displayName = 'BaSeEmoticon';
