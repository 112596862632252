import * as React from 'react';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { useConfigByAuthServerUrl } from '../../../hooks/config-by-auth-server-url';
import { BaSeTheme } from '../../../theme';
import { nameAbbreviation, urlSanitizer } from '../../../utils/string-utils';
import { BaSeLoadingSpinner } from '../../loading/spinner';
import { extractFullName, getAvatarUrl } from './avatar-controller';
import { BaSeAvatarProps } from './avatar-props';
import { StyledAvatar, StyledSpan } from './avatar-styled';

export const BaSeAvatar: React.FC<BaSeAvatarProps> = ({
  keycloak,
  imageSrc: externalImageSrc,
  userId: externalUserId,
  userName: externalUserName,
  avatarUrl: externalAvatarUrl,
  iconColor = BaSeTheme.colors.institucionais.azulSebrae36,
  iconSize = 16,
  isBoldIcon = false,
  onErrorLoading = () => {},
  onLoadSuccess = () => {},
}) => {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const { getConfig, configIsInitialized } = useConfigByAuthServerUrl();

  const [hasAvatar, setHasAvatar] = React.useState(true);

  const avatarUrl = React.useMemo(() => {
    const whenNotFoundConfig = externalAvatarUrl
      ? urlSanitizer(externalAvatarUrl)
      : undefined;
    if (configIsInitialized) {
      return (
        getConfig({
          authServerUrl: keycloak?.authServerUrl,
          key: 'avatarUrl',
        }) ?? whenNotFoundConfig
      );
    }
    return whenNotFoundConfig;
  }, [
    configIsInitialized,
    getConfig,
    keycloak?.tokenParsed,
    keycloak?.authServerUrl,
    externalAvatarUrl,
  ]);
  const userId = React.useMemo(() => {
    if (configIsInitialized) {
      return (
        keycloak?.tokenParsed?.[
          getConfig({
            authServerUrl: keycloak?.authServerUrl,
            key: 'jwtAttrId',
          }) as string
        ] ?? externalUserId
      );
    }
    return externalUserId;
  }, [
    configIsInitialized,
    getConfig,
    keycloak?.tokenParsed,
    keycloak?.authServerUrl,
    externalUserId,
  ]);
  const userName = React.useMemo(() => {
    if (configIsInitialized) {
      return extractFullName({ getConfig, keycloak }) ?? externalUserName;
    }
    return externalUserName;
  }, [
    configIsInitialized,
    getConfig,
    keycloak?.tokenParsed,
    keycloak?.authServerUrl,
    externalUserName,
  ]);

  const imageSrc = React.useMemo(
    () =>
      externalImageSrc ??
      getAvatarUrl({ getConfig, avatarUrl, userId, keycloak }),
    [
      externalImageSrc,
      avatarUrl,
      userId,
      getConfig,
      keycloak?.tokenParsed,
      keycloak?.authServerUrl,
    ],
  );

  if (!userName) {
    throw Error('[BaSeAvatar] Nome é obrigatório');
  }

  React.useEffect(() => setHasAvatar(!!imageSrc), [imageSrc]);

  if (!configIsInitialized) {
    return (
      <BaSeLoadingSpinner
        diameter={iconSize}
        color={iconColor}
        description={getMessage('loading.description')}
      />
    );
  }

  // TODO: Encapsular num container para ele ter o estilo e o shape adequado

  if (hasAvatar) {
    return (
      <StyledAvatar
        loading="lazy"
        alt={getMessage('avatarImage.description')}
        key={userId}
        src={imageSrc}
        onLoad={() => onLoadSuccess()}
        onError={() => {
          setHasAvatar(false);
          onErrorLoading();
        }}
      />
    );
  }

  return (
    <StyledSpan
      key={userId}
      isBoldIcon={isBoldIcon}
      aria-hidden={true}
      iconColor={iconColor}
      iconSize={iconSize}
    >
      {nameAbbreviation(userName)}
    </StyledSpan>
  );
};

BaSeAvatar.displayName = 'BaSeAvatar';
